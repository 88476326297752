/*
| Developed by Starton
| Filename : urls.config.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import getConfig from 'next/config'

/*
|--------------------------------------------------------------------------
| Get public runtime env
|--------------------------------------------------------------------------
*/
const {
	publicRuntimeConfig: { processEnv },
} = getConfig()

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export const API_URL = processEnv.NEXT_PUBLIC_AUTH_API_URL

/*
|--------------------------------------------------------------------------
| Config
|--------------------------------------------------------------------------
*/
export const UrlsConfig = {
	home: '/projects',
	auth: {
		verification: (email: string) => '/auth/verification?email=' + email,
		verify: (emailVerified: 'success' | 'error') => `/auth/verify?emailVerified=${emailVerified}`,
		settings: '/auth/settings',
		login: '/auth/login',
		createAccount: '/auth/create-account',
		forgotPassword: '/auth/forgot-password',
		resetPassword: '/auth/reset-password',
		tac: 'https://www.starton.com/terms-and-conditions',
	},
	billing: '/billing',
	project: {
		dashboard: '/dashboard',
		transactionManager: '/transaction-manager',
		smartContracts: '/smart-contract',
		ipfs: '/ipfs',
		storage: '/ipfs',
		monitor: '/monitor',
		rpcNode: '/rpc-node',
		tools: '/tools',
		developer: '/developer',
		settings: {
			users: '/settings?activeTab=users',
			information: '/settings?activeTab=information',
		},
		playground: {
			home: '/playground',
			mintNft: '/playground/exercises/mint-your-first-nft',
			trackSmartContractEvents: '/playground/exercises/track-smart-contract-events',
		},
	},
	smartContract: {
		auditedDocUrl:
			'https://github.com/starton-io/smart-contract-templates/blob/master/audits/Starton Security Analysis by Pessimistic.pdf',
	},
	tools: {
		zapier: 'https://zapier.com/apps/starton/integrations',
		linkedin: 'https://www.linkedin.com/company/starton-io/',
		github: 'https://github.com/starton-io/boilerplate-sign-in-with-wallets-next-js',
		discord: 'https://discord.starton.studio/',
		twitter: 'https://twitter.com/starton_io?s=20&t=2dFNKvsV9bL__2lumu-kRA',
		nftai: 'https://github.com/starton-io/AI-generated-NFT-collection',
		mail: 'hello@starton.com',
		mailTo: 'mailto:hello@starton.com',
	},
	social: {
		discord: 'https://discord.starton.com',
		linkedin: 'https://www.linkedin.com/company/starton-io/',
		twitter: 'https://twitter.com/starton_io?s=20&t=2dFNKvsV9bL__2lumu-kRA',
		mail: 'hello@starton.com',
		mailTo: 'mailto:hello@starton.com',
		youtube: 'https://www.youtube.com/channel/UC_RJ_I5zjvj3SY9x8T4jyxw',
	},
	resources: {
		github: {
			smartContractTemplates: 'https://github.com/starton-io/smart-contract-templates',
			nodejsExamples: 'https://github.com/starton-io/web3-nodejs-examples',
		},
		plugins: {
			zapier: 'https://zapier.com/app/login?next=%2Fdeveloper%2Fpublic-invite%2F122867%2Fcc4c6dd705e4216b45be9f004b155131%2F',
		},
		requests: {
			home: 'https://starton.canny.io/',
			plugin: 'https://starton.canny.io/plugins-requests',
			smartContractTemplate: 'https://starton.canny.io/smart-contracts-template-requests',
		},
	},
	doc: {
		home: 'https://docs.starton.com',
		intro: 'https://docs.starton.com/intro',
		relayer: {
			settings: {
				stuckTransactionReplacement: 'https://docs.starton.com/docs/transactions/stuck-transaction-replacement',
				settingYourRelayerStrategy:
					'https://docs.starton.com/docs/transactions/stuck-transaction-replacement#setting-your-relayer-strategy',
				apiReference:
					'https://docs.starton.com/api-reference#tag/transaction-manager/%5Bpatch%5D/v3/setting/relayer/%7Bnetwork%7D',
			},
		},
		smartContract: {
			home: 'https://docs.starton.com/docs/smart-contract/smart-contract-management',
			deploy: 'https://docs.starton.com/docs/Smart-contract/deploying-a-smart-contract',
			import: 'https://docs.starton.com/docs/Smart-contract/importing-smart-contract',
			apiReference: 'https://docs.starton.com/api-reference#tag/smart-contract-management',
			deployMyFirstERC20Contract: 'https://docs.starton.com/tutorials/deploy-first-smart-contract',
			createAMetaTransaction: 'https://docs.starton.com/tutorials/metatransaction',
			generateMyNFTCollectionWithAI: 'https://docs.starton.com/tutorials/deploy-first-smart-contract',
		},
		storage: {
			home: 'https://docs.starton.com/docs/ipfs/file-storage',
			apiReference: 'https://docs.starton.com/api-reference#tag/ipfs',
		},
		gas: {
			home: 'https://docs.starton.com/docs/Transactions/understanding-gas',
		},
		wallet: {
			home: 'https://docs.starton.com/docs/Wallet/about-wallets',
			createWallet: 'https://docs.starton.com/docs/Wallet/creating-a-wallet',
			connectKms: 'https://docs.starton.com/docs/Wallet/connecting-aws-kms',
			grantFullAccess: 'https://docs.starton.com/docs/Wallet/grantfull-access',
			messageSigning: 'https://docs.starton.com/docs/Wallet/message-signing',
			withdraw: 'https://docs.starton.com/docs/Wallet/withdraw#withdrawing-funds-from-a-wallet',
			apiReference: 'https://docs.starton.com/api-reference#tag/wallet',
		},
		transactions: {
			home: 'https://docs.starton.com/docs/transactions/transaction-management',
			apiReference: 'https://docs.starton.com/api-reference#tag/transaction-manager',
			stateAndStatus: 'https://docs.starton.com/docs/transactions/state-and-status',
			understandingGas: 'https://docs.starton.com/docs/transactions/understanding-gas',
			understandingNonce: 'https://docs.starton.com/docs/transactions/understanding-nonce',
			createATransaction: 'https://docs.starton.com/docs/transactions/creating-a-transaction',
			stuckTransactionRemplacement: 'https://docs.starton.com/docs/transactions/stuck-transaction-replacement',
			metaTransaction: 'https://docs.starton.com/tutorials/metatransaction',
		},
		monitor: {
			home: 'https://docs.starton.com/docs/watcher/understanding-watchers',
			understandingWebhooks: 'https://docs.starton.com/docs/watcher/webhooks',
			retryingWebhook: 'https://docs.starton.com/docs/watcher/webhooks#retrying-a-webhook',
			apiReference: 'https://docs.starton.com/api-reference#tag/monitor',
		},
		dashboard: {
			home: 'https://docs.starton.com/docs/Dashboard/',
		},
		billing: {
			managePlans: 'https://docs.starton.com/docs/Settings/managing-plans',
		},
		tutorials: 'https://docs.starton.com/tutorials',
		gettingStarted: 'https://docs.starton.com/docs/getting-started',
	},
	servicesStatuses: 'https://status.starton.com/en/',
	typeform: {
		sales: 'https://form.typeform.com/to/LVwVufqI',
		contactUs: 'https://form.typeform.com/to/LVwVufqI',
	},
	website: {
		pricing: 'https://www.starton.com/pricing',
	},
	playground: {
		opensea: {
			collection: (networkName: string, address: string) =>
				`https://testnets.opensea.io/assets/${networkName}/${address}/0`,
		},
	},
}

/*
| Developed by Starton
| Filename : i18n.js
*/

// const formatters = {
// 	en: new Intl.NumberFormat('en-EN'),
// 	fr: new Intl.NumberFormat('fr-FR'),
// }

module.exports = {
	locales: ['en'],
	defaultLocale: 'en',
	pages: {
		// COMMON
		'*': ['common', 'errors'],
		// AUTH
		'/auth/login': ['auth/login', 'auth/common-no-auth'],
		'/auth/create-account': ['auth/create-account', 'auth/common-no-auth'],
		'/auth/recovery': ['auth/recovery', 'auth/common-no-auth'],
		'/auth/verification': ['auth/verification', 'auth/common-no-auth'],
		'/auth/verify': ['auth/verify', 'auth/common-no-auth'],
		'/auth/error': ['auth/error', 'auth/common-no-auth'],
		'/auth/connect': ['auth/connect', 'auth/common-no-auth'],
		'/auth/settings': ['auth/settings', 'auth/common-no-auth'],
		'/auth/social-login-error': ['auth/social-login-error', 'auth/common-no-auth'],
		'/auth/reset-password': ['auth/reset-password', 'auth/common-no-auth'],
		'/auth/forgot-password': ['auth/forgot-password', 'auth/common-no-auth'],
		// WEB APP
		'/': ['index'],
		'/project-setup': ['projectSetup', 'projectList'],
		'/projects/[projectId]/ipfs': ['ipfs'],
		'/projects/[projectId]/dashboard': ['dashboard'],
		'/projects/[projectId]/billing': ['billing'],
		'/projects/[projectId]/billing/success': ['billing'],
		'/projects/[projectId]/ipfs/[IPFSId]': ['ipfs'],
		'/projects/[projectId]/monitor/[watcherId]': ['watcher'],
		'/projects/[projectId]/monitor': ['watcher'],
		'/projects/[projectId]/rpc-node': ['rpc-node/list'],
		'/projects/[projectId]/transaction-manager': [
			'transaction-manager',
			'transactions',
			'wallet',
			'kms',
			'relayerSettings',
		],
		'/projects/[projectId]/transaction-manager/wallet/[walletAddress]': ['transaction-manager', 'wallet'],
		'/projects/[projectId]/smart-contract': [
			'smartContract',
			'watcher',
			'smart-contract/easy-deploy',
			'smart-contract/home',
		],
		'/projects/[projectId]/smart-contract/[network]/[smartContractAddress]': ['smartContract', 'watcher'],
		'/projects/[projectId]/settings': ['settings', 'projectList', 'wallet'],
		'/projects/[projectId]/developer': ['developer', 'webhook'],
		'/projects': ['projectList'],
		'/projects/[projectId]/tools': ['tools'],
		'/projects/[projectId]/playground': ['playground'],
		'/projects/[projectId]/playground/exercises/mint-your-first-nft': ['playground'],
		'/projects/[projectId]/playground/exercises/track-smart-contract-events': ['playground'],
	},
	// interpolation: {
	// 	format: (value, format, lang) => {
	// 		if (format === 'number') return formatters[lang]?.format(value) ?? value
	// 		return value
	// 	},
	// },
	loadLocaleFrom: async (lang, ns) => {
		// You can use a dynamic import, fetch, whatever. You should
		// return a Promise with the JSON file.
		// yaml already being transformed by webpack plugin
		const m = require(`./locales/${lang}/${ns}.yml`)

		// eslint-disable-next-line no-undef
		return Promise.resolve(m.default)
	},
}
